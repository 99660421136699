
<template>
    <div style="padding: 0;">
        <CSDialog
            :dialogVisible="commodityInformationVisivle"
            dialogTitle="商品信息" dialog-header-class="alert-bg"
            dialogWidth="850px"
            dialogCancelBtnText="关闭"
            :dialogShowConfirmBtn="false"
            @onClose="commodityInformationVisivle = false"
            style="margin: 0; padding-top: 0;"
        >
            <div class="row" slot="dialog-content" style="padding: 20px 0;box-sizing: border-box;">
                <div class="col-sm-6">
                    <div
                        id="demo"
                        class="carousel slide"
                        data-ride="carousel"
                    >
                        <el-carousel
                            height="410px"
                            :autoplay="false"
                            style="padding-left:30px"
                        >
                            <el-carousel-item
                                v-for="(item, n) in parseJSONStr(
                                    commodityInfo.photos
                                )"
                                :key="n"
                                style="margin:0 0px 0 0px;width:100%; border-radius: 5px;"

                            >
                                <img
                                    :src="item"
                                    style="
                                        width:100%;
                                    "
                                />
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div>
                        <div  class="el-top-name">
                            <div></div>
                            <div> {{ commodityInfo.name }}</div>
                        </div>
                        <span
                            style="color: #FF5A5A; font-size: 24px;margin-left:-5px"
                            >￥{{ commodityInfo.price }}</span
                        >
                        <span
                            v-if="
                                commodityInfo.originalPrice &&
                                commodityInfo.originalPrice != ''
                            "
                            style="
                                margin-left: 10px;
                                color: #999;
                                text-decoration: line-through;
                                font-size:16px
                            "
                            >￥{{
                                commodityInfo.originalPrice
                            }}</span
                        >
                    </div>
                    <!-- <div style="fonts-zie: 18px; margin-bottom: 10px">
                        {{ commodityInfo.name }}
                    </div> -->
                    <div
                    class="cHtml"
                    v-html="commodityInfo.content"
                        style="
                            height: 300px;
                            padding: 0px 20px 10px 20px;
                            border-radius: 8px;
                            border: 1px solid #eee;
                            margin-top:10px;
                            background: #F0F0F0;
                            margin-right:30px;
                            overflow-y: auto;
                        "
                    >
                        <!-- <div
                         v-html="commodityInfo.content"
                        style="
                            height:100%;
                            overflow-y: auto;
                        ">

                        </div> -->
                    </div>
                </div>
            </div>
        </CSDialog>
    </div>
</template>
<script>
import { queryMallCommodityUrl } from "@/requestUrl";
import CSDialog from "@/components/common/CSDialog";

export default {
    props: {},
    components: {
        CSDialog,
    },
    data() {
        return {
            commodityInfo: {
                regionCode: "",
                name: "",
                photos: "",
                content: "",
                originalPrice: 1, // 原价，单位分
                price: 1, // 售价，单位分
                stock: 1, // 库存
                sales: 1, // 销量
                createTime: "",
            },
            commodityInformationVisivle: false,
        };
    },
    mounted() {
        this.$vc.on(this.$route.path, "viewCommodityModal", "view", this.listenerModal);
    },
    methods: {
        parseJSONStr(str) {
            try {
                return JSON.parse(str);
            } catch (err) {
                return [];
            }
        },
        async listenerModal(commodity) {
            if (commodity.isOrder) {
                this.commodityInfo = await this.getCommodityInfo(
                    commodity.itemId
                );
            } else {
                this.commodityInfo = commodity;
            }

            this.commodityInformationVisivle = true;
        },
        // 获取商品信息
        getCommodityInfo(id) {
            return this.$fly
                .get(queryMallCommodityUrl, {
                    id,
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    return res.data;
                });
        },
    },
};
</script>
<style scoped>
.el-top-name{

}
.el-top-name div:nth-child(1){
    width: 8px;
    height: 24px;
    background: #00B694;
    margin-right: 10px;
    display: inline-block;
    vertical-align: top;
}
.el-top-name div:nth-child(2){
    font-size: 24px;
    font-weight: 400;
    display: inline-block;
    width: 380px;
    /*height: 2px;*/
    line-height: 1;
    vertical-align: middle;
}
.cHtml{
    position: relative;
}
.cHtml::after{
    contain: "";
    width: 1000px;
    display: block;
    height: 10px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: red;
}
</style>
