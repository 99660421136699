<template>
  <div>
    <CSTabBar
        :checkedTab="checkedTabbar"
        :tabbar="tabbar"
        style=""
        @changeTabBar="changeTabBar"
    ></CSTabBar>
    <template v-if="checkedTabbar == TAB_BAR_TYPES.MALL_TAB">
      <div style="width: 100%; margin-bottom: 20px">
        <button
            :class="[
            'btn',
            'btn-sm',
            'btn-secondary',
            {
              'btn-primary': queryParams.available,
            },
          ]"
            style="width: 150px; height: 40px; border-radius: 4px 0 0 4px"
            type="button"
            @click="changeShelvesTab(true)"
        >
          上架商品
        </button>
        <button
            :class="[
            'btn',
            'btn-sm',
            'btn-secondary',
            {
              'btn-primary': !queryParams.available,
            },
          ]"
            style="width: 150px; height: 40px; border-radius: 0 4px 4px 0"
            type="button"
            @click="changeShelvesTab(false)"
        >
          下架商品
        </button>
      </div>
    </template>
    <template v-if="checkedTabbar != TAB_BAR_TYPES.PICK_UP">
      <div class="filter-panel" style="width: 100%">
        <input
            v-model="queryParams.keyword"
            placeholder="搜索商品ID/标题"
            style="
            color: #000;
            margin-bottom: 15px;
            vertical-align: middle;
            width: 200px;
            height: 30px;
            padding-left: 10px;
            border-radius: 4px;
            border: 1px solid #979797;
            margin-right: 30px;
          "
            type="text"
        />
        <button
            class="btn btn-primary"
            type="button"
            @click="changeTabBar(checkedTabbar, 1)"
        >
          查询
        </button>
      </div>
    </template>
    <div class="result-panel">
      <!-- 订单管理表 -->
      <CSTable v-if="checkedTabbar == TAB_BAR_TYPES.ORDER_TAB" :thead-top="60">
        <template v-slot:thead>
          <tr>
            <th class="text-center">下单时间</th>
            <th class="text-center">用户信息</th>
            <th class="text-center">商品信息</th>
            <th class="text-center">单价(现价)</th>
            <th class="text-center">购买量</th>
            <th class="text-center">实付金额</th>
            <th class="text-center">取货方式</th>
            <th class="text-center">评价</th>
            <th class="text-center">订单状态</th>
            <th class="text-center">操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="(order, n) in orderList" :key="n">
            <td class="text-center date-td">
              {{ order.createTime }}
            </td>
            <td class="text-center">
              <p style="margin-bottom: 0">
                {{ order.userName || "-" }}
                {{ order.userPhone ? `【${order.userPhone}】` : "-" }}
              </p>
              <p style="margin-bottom: 0">
                {{ order.userAddress || "-" }}
              </p>
            </td>
            <td class="text-center">
              <div
                  class="table-commodity-info"
                  style="text-align: center; margin: 0 auto"
                  @click="viewCommodityInfo(order, true)"
              >
                <img
                    :src="order.itemFirstPic"
                    alt=""
                    style="width: 60px; height: 60px"
                />
                <div
                    style="
                    width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-align: left;
                  "
                >
                  <p class="allow-click">
                    {{ order.itemName }}
                  </p>
                  <p class="allow-click">
                    商品id:
                    {{ order.itemId }}
                  </p>
                </div>
              </div>
            </td>
            <td class="text-center">
              {{ order.moneyAmount / order.itemNum }}
            </td>
            <td class="text-center">
              {{ order.itemNum }}
            </td>
            <td class="text-center">
              {{ order.moneyAmount }}
            </td>
            <td class="text-center">
              {{ deliveryTypesStr[order.deliveryType] || "-" }}
            </td>
            <td class="text-center">
              <template v-if="order.feedbackData">
                <div class="text-left">
                  星级：
                  {{ order.feedbackData.score }}
                </div>
                <div class="text-left">
                  评价：
                  {{
                    order.feedbackData.content
                        ? order.feedbackData.content
                        : "-"
                  }}
                </div>
              </template>
              <template v-else>-</template>
            </td>
            <td class="text-center">
              <template v-if="order.state == 2">
                {{ deliveryStateStr[order.deliveryState] || "-" }}
              </template>
              <template v-else>
                {{ orderStateStr[order.state] || "-" }}
              </template>
            </td>
            <td style="text-align: center">
              <div v-if="order.state == 2" class="btn-group">
                <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                >
                  操作
                </button>
                <ul
                    class="dropdown-menu"
                    style="min-width: 76px; width: 76px; padding: 0"
                >
                  <li
                      v-if="[1, 2, 4].includes(order.deliveryState)"
                      @click="showSignCommodityModal(order.id)"
                  >
                    <a style="width: 100%; padding: 3px 0">签收</a>
                  </li>
                  <li @click="refundOrder(order.paymentSerialNo)">
                    <a style="width: 100%; padding: 3px 0">退款</a>
                  </li>
                </ul>
              </div>
              <div v-else class="btn-group">
                <button
                    :disabled="true"
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn dropdown-toggle"
                    data-toggle="dropdown"
                    style="background: #cccccc; color: #ffff;box-shadow: none;"
                    type="button"
                >
                  操作
                </button>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>

      <!-- 商品上下架表 -->
      <CSTable v-if="checkedTabbar == TAB_BAR_TYPES.MALL_TAB" :thead-top="60">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <template
                v-if="
                queryParams.available && checkedTabbar == TAB_BAR_TYPES.MALL_TAB
              "
            >
              <button
                  class="btn btn-primary sticky-right"
                  @click="showEditCommodityModal(null)"
              >
                <svg aria-hidden="true" class="icon">
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                上架新商品
              </button>
            </template>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th class="text-center">
              {{ queryParams.available ? "上" : "下" }}架时间
            </th>
            <th class="text-center">商品信息</th>
            <th class="text-center">商品描述</th>
            <th class="text-center">单价(现价)</th>
            <th class="text-center">剩余库存量</th>
            <th class="text-center">已售量</th>
            <th class="text-center">操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="(item, index) in commodityList" :key="index">
            <td class="text-center date-td">
              {{ item.createTime || "-" }}
            </td>
            <td class="text-center">
              <div
                  class="table-commodity-info"
                  @click="viewCommodityInfo(item)"
              >
                <img :src="item.photos | getFirstPhotos" alt=""/>
                <div
                    style="
                    width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-align: center;
                  "
                >
                  <p class="allow-click">{{ item.name }}</p>
                  <p class="allow-click">
                    商品id:
                    {{ item.id }}
                  </p>
                </div>
              </div>
            </td>
            <td class="text-center">{{ item.depict || "-" }}</td>
            <td class="text-center">
              {{ item.price }}
            </td>
            <td class="text-center">
              {{ item.stock }}
            </td>
            <td class="text-center">
              已售:
              {{ item.sales || 0 }}
            </td>
            <td class="text-center">
              <div class="btn-group">
                <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                >
                  操作
                </button>
                <ul
                    class="dropdown-menu"
                    style="min-width: 76px; width: 88px; padding: 0"
                >
                  <li @click="showEditCommodityModal(item)">
                    <a style="width: 100%; padding: 3px 0">编辑</a>
                  </li>
                  <li @click="showChangeCommodityStatusModal(item)">
                    <a style="width: 100%; padding: 3px 0">
                      {{ item.available ? "下架" : "重新上架" }}
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>

      <!-- 自提点 -->
      <CSTable v-if="checkedTabbar == TAB_BAR_TYPES.PICK_UP" :thead-top="0">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <button
                class="btn btn-primary sticky-right"
                @click="openPickupDialog(null)"
            >
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              添加自提点
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>创建时间</th>
            <th>自提地址</th>
            <th>自提点状态</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="address in pickupAddressList" :key="address.id">
            <td>{{ address.createTime }}</td>
            <td>{{ address.location }}</td>
            <td>
              {{ address.enable ? "启用" : "禁用" }}
            </td>
            <td>
              <div class="btn-group">
                <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                >
                  操作
                </button>
                <ul
                    class="dropdown-menu"
                    style="min-width: 76px; width: 76px; padding: 0"
                >
                  <li @click="openPickupDialog(address)">
                    <a style="width: 100%; padding: 3px 0">编辑</a>
                  </li>
                  <li
                      @click="
                      enablePickupInfo = {
                        visible: true,
                        id: address.id,
                        enable: !address.enable,
                      }
                    "
                  >
                    <a style="width: 100%; padding: 3px 0">
                      {{ address.enable ? "禁用" : "启用" }}
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>

      <!-- 编辑商品弹窗 -->
      <EditCommodityModal
          componentName="EditCommodityModal"
          name="editCommodityModal"
      ></EditCommodityModal>
      <!-- 分页 -->
      <Pagination componentName="Pagination" name="pagination"></Pagination>
      <!-- 查看商品弹窗 -->
      <ViewCommodityModal
          componentName="ViewCommodityModal"
          name="viewCommodityModal"
      ></ViewCommodityModal>
    </div>

    <!-- 自提点添加弹窗 -->
    <CSDialog
        :dialogTitle="pickUpPoint"
        :dialogVisible="editPickupLocationInfo.visible"
        :is-submitting="isSubmitting"
        dialogWidth="580px"
        @onClose="editPickupLocationInfo.visible = false"
        @onConfirm="commitPickupInfo"
    >
      <div slot="dialog-content" style="padding: 20px 30px; font-size: 24px">
        <label style="margin-right: 40px; vertical-align: middle">
          自提点
        </label>
        <input
            v-model="editPickupLocationInfo.location"
            placeholder="请输入地址"
            style="
            width: 400px;
            height: 40px;
            border: 1px solid #999;
            border-radius: 4px;
            padding-left: 10px;
            vertical-align: middle;
          "
            type="text"
        />
      </div>
    </CSDialog>

    <!-- 自提点更改状态弹窗 -->
    <CSDialog
        :dialogVisible="enablePickupInfo.visible"
        dialog-confirm-btn-text="确定"
        dialog-header-class=" "
        dialog-header-icon="icon-menua-zu13"
        dialogTitle="提示"
        dialogWidth="580px"
        @onClose="enablePickupInfo.visible = false"
        @onConfirm="changePckupEnable"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px; font-size: 24px; text-align: center"
      >
        确定{{ enablePickupInfo.enable ? "启" : "禁" }}用吗?
      </div>
    </CSDialog>
  </div>
</template>
<script>
import EditCommodityModal from "@/components/EditCommodityModal.vue";
import Pagination from "@/components/Pagination.vue";
import ViewCommodityModal from "@/components/ViewCommodityModal.vue";
import CSTabBar from "@/components/common/CSTabBar";
import CSDialog from "@/components/common/CSDialog";

import {
  addPickupLocationUrl,
  changeMallAvailableUrl,
  changePickupEnableUrl,
  editPickupLocationUrl,
  queryMallItemsUrl,
  queryMallOrderUrl,
  queryPickupLocationUrl,
  refundOrderUrl,
  signForDeliveryUrl,
} from "@/requestUrl";
import CSTable from "@/components/common/CSTable";

const TAB_BAR_TYPES = {
  ORDER_TAB: 1,
  MALL_TAB: 2,
  PICK_UP: 3,
};

// 取货方式
const DELIVERY_TYPE = {
  // 自取
  SELF_PICK_UP: 1,
  //配送
  DISTRIBUTION: 2,
};

export default {
  props: {},
  destroyed() {
    window.removeEventListener("keydown", this.createdDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this.createdDown);
  },
  components: {
    CSTable,
    EditCommodityModal,
    Pagination,
    ViewCommodityModal,
    CSTabBar,
    CSDialog,
  },
  data() {
    return {
      seavePage: 1,
      page: 1,
      DELIVERY_TYPE,
      deliveryTypesStr: {
        [DELIVERY_TYPE.SELF_PICK_UP]: "自提",
        [DELIVERY_TYPE.DISTRIBUTION]: "配送",
      },
      pickUpPoint: "",
      orderStateStr: {
        1: "未付款",
        2: "已付款",
        3: "已退款",
      },
      deliveryStateStr: {
        1: "未配送",
        2: "配送中",
        3: "已签收",
        4: "待自提",
        5: "已评价",
      },
      queryParams: {
        available: true,
        keyword: "",
      },
      TAB_BAR_TYPES,
      checkedTabbar: TAB_BAR_TYPES.MALL_TAB,
      commodityList: [],
      orderList: [],
      pickupAddressList: [],
      // 自提点状态
      enablePickupInfo: {
        visible: false,
        id: null,
        enable: null,
      },
      isSubmitting: false,
      // 添加，编辑自提点的参数
      editPickupLocationInfo: {
        visible: false, // 控制弹窗显示，隐藏
        location: "",
        id: null,
      },
      tabbar: {
        1: "订单管理",
        2: "福利商城管理",
        3: "自提点管理",
      },
      commodityInformationVisivle: false,
    };
  },
  filters: {
    /**
     * 获取返回的图片列表第一张图
     * @param {String} photosStr 图片json数组
     *  */
    getFirstPhotos(photosStr) {
      try {
        return JSON.parse(photosStr)[0];
      } catch (err) {
        return [];
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.createdDown);
    this.changeTabBar(TAB_BAR_TYPES.ORDER_TAB);
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.page = pageNo;
      switch (this.checkedTabbar) {
        case TAB_BAR_TYPES.ORDER_TAB:
          this.getOrderList(pageNo);
          break;
        case TAB_BAR_TYPES.MALL_TAB:
          this.seavePage = pageNo;
          this.getCommodityList(pageNo);
          break;
        case TAB_BAR_TYPES.PICK_UP:
          this.seavePage = pageNo;
          this.getPickupList(pageNo);
          break;
      }
      this.getPickupList(pageNo);
    });
    this.$vc.on(
        this.$route.path,
        "mallMange",
        "orderList",
        "refresh",
        (pag) => {
          this.getOrderList(pag);
        }
    );
    this.$vc.on(
        this.$route.path,
        "mallMange",
        "commodityList",
        "refresh",
        (pag) => {
          this.getCommodityList(pag);
        }
    );
  },
  methods: {
    createdDown(e) {
      if (e.keyCode == 13) {
        this.changeTabBar(this.checkedTabbar, 1);
      }
    },
    // 更改自提点启用状态
    changePckupEnable() {
      const {id, enable} = this.enablePickupInfo;
      this.$fly
          .post(changePickupEnableUrl, {
            id,
            enable,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            this.enablePickupInfo.visible = false;
            console.log(this.seavePage);
            this.getPickupList(this.seavePage);
          });
    },
    // 提交自提点
    commitPickupInfo() {
      const {id, location} = this.editPickupLocationInfo;
      const params = {
        regionCode: this.$vc.getCurrentRegion().code,
        id,
        location,
      };
      if (id) {
        delete params.regionCode;
      }
      this.isSubmitting = true;
      this.$fly
          .post(id ? editPickupLocationUrl : addPickupLocationUrl, params)
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            this.editPickupLocationInfo.visible = false;
            this.getPickupList();
          })
          .finally(() => (this.isSubmitting = false));
    },
    /**
     * 打开自提点弹窗
     * @param {Object} info 自提点信息 如果为空则认为是添加自提点
     */
    openPickupDialog(info) {
      if (info) {
        const {id, location} = info;
        this.editPickupLocationInfo = {
          visible: true,
          id,
          location,
        };
        this.pickUpPoint = "修改自提点";
        return;
      }
      this.pickUpPoint = "添加自提点";
      this.editPickupLocationInfo = {
        visible: true,
        location: "",
      };
    },
    //  获取自提点
    getPickupList(pageNo = 1, pageSize = 10) {
      this.$fly
          .get(queryPickupLocationUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            pageNo,
            pageSize,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            if (pageNo == 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize: pageSize,
              });
            }
            this.pickupAddressList = res.data.datas;
          });
    },
    showSignCommodityModal(orderId) {
      this.$CSDialog.confirm({
        title: "提示",
        message: "确定对方已签收商品?",
        onConfirm: () => {
          this.$fly
              .post(signForDeliveryUrl, {
                orderId,
              })
              .then((res) => {
                if (res.code !== 0) {
                  return;
                }
                this.$CSDialog.instance.closeDialog();
                this.getOrderList(this.page);
              });
        },
      });
    },
    /**
     * 代开查看商品弹窗
     * @param {Object} commodityInfo 商品信息
     * @param {Boolean} isOrder 是不是订单数据
     *  */
    viewCommodityInfo(commodityInfo, isOrder = false) {
      commodityInfo.isOrder = isOrder;
      this.$vc.emit(
          this.$route.path,
          "viewCommodityModal",
          "view",
          commodityInfo
      );
    },
    showEditCommodityModal(commodityInfo) {
      this.$vc.emit(
          this.$route.path,
          "editCommodityModal",
          "show",
          commodityInfo
      );
    },
    showChangeCommodityStatusModal(commodityInfo) {
      this.$CSDialog.confirm({
        title: "提示",
        message: `确定${commodityInfo.available ? "下" : "上"}架吗?`,
        onConfirm: () => {
          this.$fly
              .post(changeMallAvailableUrl, {
                regionCode: this.$vc.getCurrentRegion().code,
                id: commodityInfo.id,
                available: !commodityInfo.available,
              })
              .then((res) => {
                if (res.code !== 0) {
                  return;
                }
                this.$vc.toast(`${commodityInfo.available ? "下" : "上"}架成功`);
                this.getCommodityList(this.seavePage);
                this.$CSDialog.instance.closeDialog();
              });
        },
      });
    },
    /**
     * 切换商品,订单,自提点tab
     * @param {Number} tab tab标识 TAB_BAR_TYPES
     *  */
    changeTabBar(tab, query) {
      if (this.checkedTabbar != tab) {
        this.queryParams = {
          keyword: "",
          available: true,
          orderState: "",
          deliveryType: "",
        };
      }
      this.checkedTabbar = tab;
      switch (tab) {
        case TAB_BAR_TYPES.ORDER_TAB:
          this.getOrderList();
          break;
        case TAB_BAR_TYPES.MALL_TAB:
          this.changeShelvesTab(true, query);
          break;
        case TAB_BAR_TYPES.PICK_UP:
          this.getPickupList();
          break;
      }
    },
    /**
     * 切换上下架商品
     * @param {Boolean}  available 上下架状态
     *  */
    changeShelvesTab(available = true, query) {
      if (query === 1) {
        this.getCommodityList();
        return;
      }
      this.queryParams.available = available;
      this.getCommodityList();
    },
    splitPhotosString(photosStr) {
      if (typeof photosStr == "string") {
        return photosStr.split(",");
      }
      return photosStr || [];
    },
    /**
     * 获取商品列表
     * @param {Number} pageNo 商品查看的页数
     * @param {Number} pageSize 每次查看的数量
     *  */
    getCommodityList(pageNo = 1, pageSize = 10) {
      this.$fly
          .post(queryMallItemsUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            available: this.queryParams.available, // 上架：true；下架：false；选填
            keyword: this.queryParams.keyword,
            pageNo, // 页码，选填
            pageSize, // 每页返回数量，选填
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize,
              });
            }
            this.commodityList = res.data.datas;
          });
    },
    /**
     * 获取商品订单列表
     * @param {Number} pageNo 商品订单查看的页数
     * @param {Number} pageSize 可查看商品的数量
     *  */
    getOrderList(pageNo = this.page, pageSize = 10) {
      const {keyword, orderState, deliveryType} = this.queryParams;
      this.$fly
          .post(queryMallOrderUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            keyword,
            orderState, // 1：未付款；2：已付款；3：已退款， 选填
            deliveryType, // 1：自提；2：配送， 选填
            pageNo, //选填
            pageSize, //选填
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize: pageSize,
              });
            }
            this.orderList = res.data.datas;
          });
    },
    refundOrder(paymentSerialNo) {
      this.$CSDialog.confirm({
        title: "提示",
        message: "确定退款吗?",
        onConfirm: () => {
          this.$fly
              .post(refundOrderUrl, {
                paymentSerialNo,
              })
              .then((res) => {
                if (res.code !== 0) {
                  return;
                }
                this.$CSDialog.instance.closeDialog();
                this.getOrderList();
              });
        },
      });
    },
    returnCommodity(commodities) {
      return commodities[0].commodity;
    },
  },
};
</script>


<style lang="stylus" scoped>
@import '../../public/common/static/css/component/mallManage.styl';

input::-webkit-input-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input::-ms-input-placeholder {
  color: #999;
}

.text-left {
  text-align: center !important;
}
</style>
